import React from 'react';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Toolbar,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';

const useStylesList = makeStyles((theme: Theme) =>
  createStyles({
    links: {
      color: '#636363',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: 0,
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'inline-flex',
        alignItems: 'center',
        marginRight: 0,
        marginLeft: theme.spacing(4),
      },
      '&:link': { color: '#333333' },
      '&:visited': { color: '#333333' },
      '&:action': {
        color: '#333333',
        textDecoration: 'underline',
      },
      '&:focus': {
        color: '#333333',
        textDecoration: 'underline',
      },
      '&:hover': {
        color: '#333333',
        textDecoration: 'underline',
      },
    },
    statusButton: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 'normal',
      color: '#333333',
      textAlign: 'right',
      display: 'inline-flex',
      marginLeft: theme.spacing(3),
      padding: theme.spacing(0.5, 1),
      textTransform: 'none',
    },
    paper: {
      boxShadow: '0px 5px 10px #00000014',
    },
  }),
);

export interface LinkProps {
  url: string;
  label: string;
}

interface NavigationProps {
  links?: LinkProps[];
  statusLabel?: React.ReactNode;
  onClick?: () => void;
}

export const NavigationList: React.FC<NavigationProps> = ({
  links,
  statusLabel,
  onClick,
}) => {
  const classes = useStylesList(useTheme());
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box component="nav">
      {links &&
        links.map((v, i) => {
          return (
            <Link
              component={RouterLink}
              to={v.url}
              key={`header-link-${i}`}
              className={classes.links}
            >
              {v.label}
            </Link>
          );
        })}
      <Button
        className={classes.statusButton}
        endIcon={<ArrowDropDownIcon />}
        onClick={handleClick}
      >
        {statusLabel}
      </Button>
      <Menu
        id="status-label"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={0}
        getContentAnchorEl={null}
        classes={{
          paper: classes.paper,
        }}
      >
        <MenuItem>
          <ListItemText onClick={onClick}>ログアウト</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};

const useStylesDrawer = makeStyles((theme: Theme) => ({
  bgToolbar: { minHeight: '100px' },
  menuButton: {
    color: '#7F7F7F',
  },
  dialog: {
    '& .MuiPaper-root': {
      position: 'absolute',
      top: 0,
      margin: 0,
      width: '100%',
      maxWidth: '100%',
      borderRadius: 0,
    },
  },
  dialogLinkList: {
    padding: theme.spacing(1.5, 0, 1),
    '& .MuiListItem-root': {
      padding: theme.spacing(6 / 8, 2, 5 / 8),
      '&:hover': {
        backgroundColor: '#EDEFF3',
      },
    },
    '& .MuiTypography-root': {
      textAlign: 'left',
      fontSize: '14px',
      color: '#636363',
    },
  },
  dialogButton: {
    width: 'calc(100% - 32px)',
    maxWidth: '343px',
    height: '32px',
    margin: theme.spacing(0, 'auto', 2.5),
  },
  dialogStatusText: {
    margin: '14px 0',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#AAAAAA',
  },
}));

export const NavigationDrawer: React.FC<NavigationProps> = ({
  links,
  statusLabel,
  onClick,
}) => {
  const theme = useTheme();
  const classesDrawer = useStylesDrawer(useTheme());

  const [open, setOpen] = React.useState(false);
  const handleToggle = () => {
    setOpen((prev) => !prev);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        aria-label="open menu"
        edge="end"
        onClick={handleToggle}
        className={classesDrawer.menuButton}
      >
        {open ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        style={{ zIndex: theme.zIndex.appBar - 1 }}
        className={classesDrawer.dialog}
      >
        <Toolbar className={classesDrawer.bgToolbar} />
        <List className={classesDrawer.dialogLinkList}>
          <ListSubheader className={classesDrawer.dialogStatusText}>
            {statusLabel} でログイン中
          </ListSubheader>
          {links &&
            links.map((v, i) => {
              return (
                <ListItem
                  button
                  component={RouterLink}
                  to={v.url}
                  key={`header-link-${i}`}
                >
                  <ListItemText>{v.label}</ListItemText>
                </ListItem>
              );
            })}
          <ListItem button onClick={onClick}>
            <ListItemText>ログアウト</ListItemText>
          </ListItem>
        </List>
      </Dialog>
    </>
  );
};
